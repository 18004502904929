import {
  Component,
  OnInit,
  Inject, Optional, DoCheck, Pipe, Injectable, PipeTransform, ChangeDetectorRef
} from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router, NavigationEnd, ActivatedRoute, Data, NavigationStart, NavigationError } from '@angular/router';
import { filter, map, mergeMap } from 'rxjs/operators';
import { ThemePalette } from '@angular/material/core';
import { DatePipe } from '@angular/common';

import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BreadcrumbService } from './breadcrumb.service';
import { MatIconRegistry } from "@angular/material/icon";
import { DomSanitizer } from "@angular/platform-browser";
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { VerticalAppHeaderComponent } from '../vertical-header/vertical-header.component';
import { TranslateService } from '@ngx-translate/core';
import {
  MatSnackBar, MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
} from '@angular/material/snack-bar';
import { I } from '@angular/cdk/keycodes';
import { Helper } from 'src/helper/helper';
import * as moment from 'moment';
import { FullComponent } from './../full.component';
import { SessionExpiredModalComponent } from 'src/app/session-expired-modal/session-expired-modal.component';
import { AppComponent } from 'src/app/app.component';

export interface ChipColor {
  name: string;
  color: ThemePalette;
  state: number
}

export interface Page {
  id: number;
  created_date: string;
  deleted: number;
  deleted_date: string;
  description: string;
  name_page: string;
  page_id: number;
  updated_date: string;
  language: number;
  title: number
}

@Pipe({ name: "dateTimeFormatFilter" })
@Injectable()
export class DateTimeFormatPipe implements PipeTransform {
  transform(date: any, format: string): any {
    if (date) {
      return moment(date).format(format);
    }
  }
}


@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss'],
  providers: [DateTimeFormatPipe]
})
export class AppBreadcrumbComponent implements OnInit, DoCheck {
  // @Input() layout;
  pageInfo: Data = Object.create(null);
  currentDocStatus: any;
  currentUser: any;
  descriptionPages: Page[] = [];
  showAlert: boolean = false;
  showAlertPlan: boolean = false;
  message: any;
  cbInstance: any;
  availableColors: ChipColor[] = [];
  alertPlan: ChipColor[] = [];
  dateNow: any;
  horizontalPosition: MatSnackBarHorizontalPosition = 'end'; // start, center, end, left, right
  verticalPosition: MatSnackBarVerticalPosition = 'top'; // top, bottom
  planStatus: any;
  token: any;
  isLoadingResultsPlans = false;
  contRequest = 0;
  date_for_check: any;
  currentRoute: any;
  versionProduct: any;
  logo: any = 'assets/images/new-card-icons/loading-logo-proc.svg'
  countModaleExpired = 0;
  limitations: any = [];
  limitationsArray: any = [];
  manageUserBlock: any;
  discoverCompatibleProfileBlock: any;
  timeMaterialBlock: any;
  fixedPriceBlock: any;
  showEstimateBlock: any;
  hireBlock: any;
  chatBlock: any;
  state: any;
  globalState: any;
  idPlanCurrent: any;
  is_old_plan: any;
  countPosition: any;
  max_position: any;
  transactionFee: any;
  namePlanCurrent: any;
  freePlan: any;

  requestAvailableBlock: any;
  downloadCVBlock: any;
  informationBlock: any;
  emailsContactBlock: any;
  downloadCompanyReportBlock: any;
  searchPartnerBlock: any;
  existInLimitationSearch: any;
  getPlanStatusRes: any;




  constructor(
    private http: HttpClient,
    private _helper: Helper,
    private translate: TranslateService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private titleService: Title,
    private datePipe: DatePipe,
    public dialog: MatDialog,
    private _service: BreadcrumbService,
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
    private _snackBar: MatSnackBar,
    private dateFormat: DateTimeFormatPipe,
    private fullComponent: FullComponent,
    private cdRef: ChangeDetectorRef,
    public appComponent: AppComponent,
  ) {
    this.currentUser = JSON.parse(sessionStorage.getItem("loginUser")!);
    this.matIconRegistry.addSvgIcon(
      `info-fixed`,
      this.domSanitizer.bypassSecurityTrustResourceUrl("assets/images/topbr-icons/info-fixed.svg")
    );

    this.currentRoute = "";
    this.router.events.subscribe((event: any) => {
      if (event instanceof NavigationStart) {
        // Show loading indicator
      }

      if (event instanceof NavigationEnd) {
        // Hide loading indicator
        // this.currentRoute = event.url;
      }

      if (event instanceof NavigationError) {
        // Hide loading indicator

        // Present error to user
      }
    });

    this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .pipe(map(() => this.activatedRoute))
      .pipe(
        map(route => {
          while (route.firstChild) {
            route = route.firstChild;
          }
          return route;
        })
      )
      .pipe(filter(route => route.outlet === 'primary'))
      .pipe(mergeMap(route => route.data))
      .subscribe(event => {
        this.pageInfo = event;
      });

    this.dateNow = new Date();

    this.token = JSON.parse(sessionStorage.getItem("loginUserToken")!);

    if (this.currentUser.role == "Client" || this.currentUser.role == "Supplier" || this.currentUser.role == "Procurement Specialist" || this.currentUser.role == 'Supplier Management'
      || this.currentUser.role == 'Accademy' || this.currentUser.role == 'Management Accademy') {
      this.getDocumentationStatus();
    }

    this.logo = appComponent.logoSpinner
    console.log(this.logo);
  }
  closeModal() {
    this.showAlertPlan = false;
  }
  ngAfterViewChecked(): void {
    this.manageUserBlock = this.fullComponent.manageUserBlock
    this.discoverCompatibleProfileBlock = this.fullComponent.discoverCompatibleProfileBlock
    this.timeMaterialBlock = this.fullComponent.timeMaterialBlock
    this.fixedPriceBlock = this.fullComponent.fixedPriceBlock
    this.showEstimateBlock = this.fullComponent.showEstimateBlock
    this.hireBlock = this.fullComponent.hireBlock
    this.chatBlock = this.fullComponent.chatBlock
    this.state = this.fullComponent.state
    this.globalState = this.fullComponent.globalState

    this.planStatus = this.fullComponent.planStatus;
    this.idPlanCurrent = this.fullComponent.idPlanCurrent;
    this.is_old_plan = this.fullComponent.is_old_plan;
    this.countPosition = this.fullComponent.countPosition;
    this.max_position = this.fullComponent.max_position;
    this.transactionFee = this.fullComponent.transactionFee;
    this.namePlanCurrent = this.fullComponent.namePlanCurrent;
    this.freePlan = this.fullComponent.freePlan;
    this.currentDocStatus = this.fullComponent.currentDocStatus;
    ////console.log(this.globalState);
    this.cdRef.detectChanges();
  }


  checkForModal() {

    var invocateGetPlanStatus = JSON.parse(sessionStorage.getItem('invocateGetPlanStatus')!);

    if ((invocateGetPlanStatus && invocateGetPlanStatus == 0 || !invocateGetPlanStatus)) {


      var dateInvocateGetPlanStatu = JSON.parse(sessionStorage.getItem('dateInvocateGetPlanStatu')!);
      var dateInvocateGetPlanStatuRefresh = JSON.parse(sessionStorage.getItem('dateInvocateGetPlanStatuRefresh')!);

      if (!dateInvocateGetPlanStatuRefresh || dateInvocateGetPlanStatu != dateInvocateGetPlanStatuRefresh) {
        this.fullComponent.getPlanStatus();
      }

      if (this.fullComponent.namePlanCurrent) {

        this.idPlanCurrent = this.fullComponent.idPlanCurrent;
        this.is_old_plan = this.fullComponent.is_old_plan;
        this.countPosition = this.fullComponent.countPosition;
        this.max_position = this.fullComponent.max_position;
        this.transactionFee = this.fullComponent.transactionFee;
        this.namePlanCurrent = this.fullComponent.namePlanCurrent;
        this.freePlan = this.fullComponent.freePlan

        this.planStatus = this.fullComponent.planStatus;



        this.freePlan = this.fullComponent.freePlan;

        this.limitations = this.fullComponent.limitations;
        sessionStorage.removeItem('userLimitation');
        sessionStorage.setItem('userLimitation', JSON.stringify(this.limitations))
        this.limitationsArray = JSON.parse(sessionStorage.getItem('userLimitation')!)
        //console.log(this.limitationsArray);
        this.limitationsArray.forEach((element: any) => {
          switch (element.state_function) {
            case "manage_users_supplier":
              this.manageUserBlock = element
              break;
            case "SPC":
              this.discoverCompatibleProfileBlock = element
              break;
            case "tEm":
              this.timeMaterialBlock = element
              break;
            case "PFP":
              this.fixedPriceBlock = element
              break;
            case "VP":
              this.showEstimateBlock = element
              break;
            case "accademy_profiles":
              this.hireBlock = element
              break;
            case "chat":
              this.chatBlock = element
              break;
            case "request_available":
              this.requestAvailableBlock = element
              break;
            case "download_cv":
              this.downloadCVBlock = element
              break;
            case "information_blocked":
              this.informationBlock = element
              break;
            case "emails_contact":
              this.emailsContactBlock = element
              break;
            case "download_company":
              this.downloadCompanyReportBlock = element
              break;
            case "search_partner":
              this.searchPartnerBlock = element
              this.existInLimitationSearch = true;
              break;
            default:
              break;
          }
        });

        if (this.existInLimitationSearch == false) {
          this.searchPartnerBlock = { blocked: true, action: 1 };
          this.downloadCompanyReportBlock = { blocked: true, action: 1 };
        }

        if (this.fullComponent.checkNumberDay == 0) {
          this.showAlertPlan = false;

          if (this.currentUser.language == "it") {
            this.message = this.translate.translations.it.UpgradePlan;
          } else {
            this.message = this.translate.translations.en.UpgradePlan;
          }
          this.alertPlan.push({
            name: this.message,
            color: 'warn',
            state: 6
          });
        } else {
          this.showAlertPlan = false;
        }

        sessionStorage.setItem("blockForPlan", JSON.stringify(this.showAlertPlan))!

      }
    } else {

      const format1 = "YYYY-MM-DD HH:mm:ss"
      var date2 = new Date();
      var date_for_check = moment(date2).format(format1);
      sessionStorage.setItem('dateInvocateGetPlanStatusRefresh', JSON.stringify(date_for_check));

    }

  }

  getDocumentationStatus() {

    this.currentDocStatus = this.fullComponent.currentDocStatus;
    if (this.currentDocStatus && this.currentDocStatus.id_doc_status == 4) {
      this.showAlert = true;
      if (this.dateNow > new Date(this.currentDocStatus.limit_date_documentation)) {
        if (this.currentUser.language == "it") {
          this.message = this.translate.translations.it.NoDoc1 + this.dateFormat.transform(this.currentDocStatus.limit_date_documentation, 'DD/MM/YYYY') + this.translate.translations.it.NoDoc2;
        } else {
          this.message = this.translate.translations.en.NoDoc1 + this.dateFormat.transform(this.currentDocStatus.limit_date_documentation, 'DD/MM/YYYY') + this.translate.translations.en.NoDoc2;
        }
      } else {

        if (this.currentUser.language == "it") {
          this.message = this.translate.translations.it.NoDoc3 + this.dateFormat.transform(this.currentDocStatus.limit_date_documentation, 'DD/MM/YYYY') + this.translate.translations.it.NoDoc4;
        } else {
          this.message = this.translate.translations.en.NoDoc3 + this.dateFormat.transform(this.currentDocStatus.limit_date_documentation, 'DD/MM/YYYY') + this.translate.translations.en.NoDoc4;
        }
      }
      this.availableColors.push({
        name: this.message,
        color: 'warn',
        state: 4
      });
    }
    if (this.currentDocStatus && this.currentDocStatus.id_doc_status == 1) {
      this.showAlert = true;
      if (this.currentUser.language == "it") {
        this.message = this.translate.translations.it.RevisionDoc;
      } else {
        this.message = this.translate.translations.en.RevisionDoc;
      }
      this.availableColors.push({
        name: this.message,
        color: 'warn',
        state: 1
      });
    }
    if (this.currentDocStatus && this.currentDocStatus.id_doc_status == 3 && this.currentUser.role != "Supplier Employees" && this.currentUser.role != "Profile Accademy") {
      this.router.navigate(['/authentication/login']);
    }
    if (this.currentUser.role == "Supplier" || this.currentUser.role == 'Supplier Management' || this.currentUser.role == 'Accademy' || this.currentUser.role == 'Management Accademy') {

      var invocateGetPlanStatus = JSON.parse(sessionStorage.getItem('invocateGetPlanStatus')!);

      if ((invocateGetPlanStatus && invocateGetPlanStatus == 0 || !invocateGetPlanStatus)) {


        var dateInvocateGetPlanStatu = JSON.parse(sessionStorage.getItem('dateInvocateGetPlanStatu')!);
        var dateInvocateGetPlanStatuRefresh = JSON.parse(sessionStorage.getItem('dateInvocateGetPlanStatuRefresh')!);

        if (!dateInvocateGetPlanStatuRefresh || dateInvocateGetPlanStatu != dateInvocateGetPlanStatuRefresh) {
          this.fullComponent.getPlanStatus();
        }

        if (this.fullComponent.namePlanCurrent) {

          this.idPlanCurrent = this.fullComponent.idPlanCurrent;
          this.is_old_plan = this.fullComponent.is_old_plan;
          this.countPosition = this.fullComponent.countPosition;
          this.max_position = this.fullComponent.max_position;
          this.transactionFee = this.fullComponent.transactionFee;
          this.namePlanCurrent = this.fullComponent.namePlanCurrent;
          this.freePlan = this.fullComponent.freePlan

          this.planStatus = this.fullComponent.planStatus;



          this.freePlan = this.fullComponent.freePlan;

          this.limitations = this.fullComponent.limitations;
          sessionStorage.removeItem('userLimitation');
          sessionStorage.setItem('userLimitation', JSON.stringify(this.limitations))
          this.limitationsArray = JSON.parse(sessionStorage.getItem('userLimitation')!)
          //console.log(this.limitationsArray);
          this.limitationsArray.forEach((element: any) => {
            switch (element.state_function) {
              case "manage_users_supplier":
                this.manageUserBlock = element
                break;
              case "SPC":
                this.discoverCompatibleProfileBlock = element
                break;
              case "tEm":
                this.timeMaterialBlock = element
                break;
              case "PFP":
                this.fixedPriceBlock = element
                break;
              case "VP":
                this.showEstimateBlock = element
                break;
              case "accademy_profiles":
                this.hireBlock = element
                break;
              case "chat":
                this.chatBlock = element
                break;
              case "request_available":
                this.requestAvailableBlock = element
                break;
              case "download_cv":
                this.downloadCVBlock = element
                break;
              case "information_blocked":
                this.informationBlock = element
                break;
              case "emails_contact":
                this.emailsContactBlock = element
                break;
              case "download_company":
                this.downloadCompanyReportBlock = element
                break;
              case "search_partner":
                this.searchPartnerBlock = element
                this.existInLimitationSearch = true;
                break;
              default:
                break;
            }
          });

          if (this.existInLimitationSearch == false) {
            this.searchPartnerBlock = { blocked: true, action: 1 };
            this.downloadCompanyReportBlock = { blocked: true, action: 1 };
          }

          if (this.fullComponent.checkNumberDay == 0) {
            this.showAlertPlan = false;

            if (this.currentUser.language == "it") {
              this.message = this.translate.translations.it.UpgradePlan;
            } else {
              this.message = this.translate.translations.en.UpgradePlan;
            }
            this.alertPlan.push({
              name: this.message,
              color: 'warn',
              state: 6
            });
          } else {
            this.showAlertPlan = false;
          }

          sessionStorage.setItem("blockForPlan", JSON.stringify(this.showAlertPlan))!

        }
      } else {

        const format1 = "YYYY-MM-DD HH:mm:ss"
        var date2 = new Date();
        var date_for_check = moment(date2).format(format1);
        sessionStorage.setItem('dateInvocateGetPlanStatusRefresh', JSON.stringify(date_for_check));
      }
    }

  }


  ngOnInit() {
    this.currentUser = JSON.parse(sessionStorage.getItem("loginUser")!);
    this.versionProduct = JSON.parse(sessionStorage.getItem("versionProduct")!);
    // if(this.versionProduct == 2){
    //   this.logo = 'assets/images/new-card-icons/loading-logo.svg';
    // } else if(this.versionProduct == 1){
    //   if (window.location.host == "procurement.timeflow.it") {
    //     this.logo = 'assets/images/new-card-icons/loading-logo.svg';
    //   } else if (window.location.host == "whitelabel.daytrend.it" ) {
    //     this.logo = 'assets/images/new-card-icons/loading-logo.svg';
    //   } else if (window.location.host == "procurement.daytrend.it" ) {
    //     this.logo = 'assets/images/new-card-icons/loading-logo.svg';
    //   } else if (window.location.host == "dev.mkt.daytrend.it" ) {
    //     this.logo = 'assets/images/new-card-icons/loading-logo.svg';
    //   } else {
    //     this.logo = 'assets/images/new-card-icons/loading-logo-proc.svg';
    //   }
    // }

    if ((this.currentUser.role == "Supplier" || this.currentUser.role == "Supplier Management")) {
      this.checkForModal();
    }

  }

  changeView(element: number) {
    if (element == 4) {
      this.router.navigate(['/company_profile']);
    } else if (element == 5) {
      this.router.navigate(['/my_plans']);
    } else if (element == 6) {
      this.openCheckout(8, 'piano-fornitori-cost')
    }
  }

  getFormUrlEncoded(toConvert: any) {
    const formBody = [];
    for (const property in toConvert) {
      const encodedKey = encodeURIComponent(property);
      if (toConvert[property]) {
        const encodedValue = encodeURIComponent(toConvert[property]);
        formBody.push(encodedKey + '=' + encodedValue);
      }
    }
    return formBody.join('&');
  }

  refreshSubscription(plan_id: any, date_for_check: any) {
    setTimeout(() => {
      this.showAlertPlan = false;
      this.isLoadingResultsPlans = true;
      //console.log('1');
      this.getCheckSubscriptionIsUpdated(plan_id, date_for_check)
    }, 1000);
  }

  ngDoCheck(): void {

    var weebokFunction = sessionStorage.getItem('weebook-function');
    if (weebokFunction == 'true') {
      this.isLoadingResultsPlans = false;
      //console.log('2');
      sessionStorage.removeItem("weebook-function");
    }

  }

  getCheckSubscriptionIsUpdated(plan_id: any, date_for_check: any) {

    this._service.getCheckSubscriptionIsUpdated(plan_id, date_for_check).then(res => {
      if (res.data != null && res.data != 0) {

        sessionStorage.setItem('weebook-function', 'true');
        this.isLoadingResultsPlans = false;
        //console.log('3');
        window.location.reload();


      } else {
        if (this.contRequest <= 10) {
          this.contRequest++
          this.refreshSubscription(plan_id, date_for_check);
        } else {

          sessionStorage.setItem('weebook-function', 'true');
          this.isLoadingResultsPlans = false;
          //console.log('4');
          window.location.reload();


        }
      }

    }).catch(error => {
      if (error.status == 401) {
        this.fullComponent.refreshToken(1);

      } else {
        this.manageError();
      }

    });


  }

  openCheckout(plan_id: any, name_plan_charge_bee: any) {

    if (window.location.host == 'marketplace.timeflow.it') {
      this.cbInstance = (<any>window)['Chargebee'].init({
        site: "timeflow"
      });
    } else {
      this.cbInstance = (<any>window)['Chargebee'].init({
        site: "timeflow-test"
      });
    }

    this.cbInstance.openCheckout({
      hostedPage: () => {

        let obj = {
          plan_id: plan_id,
          plan_charge: name_plan_charge_bee,
        }

        return this.http.post(this._helper.getBaseUrl() + 'chargebee/openCheckoutSubscription', this.getFormUrlEncoded(obj), { headers: new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded', 'Authorization': `Bearer ${this._helper.getUserConfigData().access_token}` }) }).toPromise();
      },
      loaded: () => {
        //console.log("checkout opened");
        const format1 = "YYYY-MM-DD HH:mm:ss"
        var date2 = new Date();
        this.date_for_check = moment(date2).format(format1);
      },
      error: (error: any) => {

        if (error.status == 401) {
          this._service.getRefreshToken(this.token.access_token).then(res => {
            sessionStorage.removeItem('loginUserToken');
            sessionStorage.setItem('loginUserToken', JSON.stringify(res.data.token));
            sessionStorage.removeItem('loginUser');
            sessionStorage.setItem('loginUser', JSON.stringify(res.data.user));
            this.openCheckout(plan_id, name_plan_charge_bee);
          }).catch(error => {
            if (error.status == 401) {
              if (this.countModaleExpired == 0) {
                this.countModaleExpired++;
                var obj;
                let dialogRef = this.dialog.open(SessionExpiredModalComponent, {
                  data: obj
                });
                dialogRef.afterClosed().subscribe(result => {
                  if (result.event == 'ExtendSession') {
                    this.countModaleExpired = 0;
                    this.openCheckout(plan_id, name_plan_charge_bee);
                  }
                })
              };
            } else {
              this.manageError();
            }

          });

        } else {
          this.manageError();
        }



      },
      close: () => {
        //console.log("checkout closed");
        this.refreshSubscription(plan_id, this.date_for_check);

      },
      success: (hostedPageId: any) => {

        //console.log(hostedPageId);
      },
      step: (value: any) => {
        //console.log(value);
      }
    });
  }


  openDialog(title: any) {
    let location = window.location.href;
    let locationSplit = location.split('/');
    let locationFinal = '';
    if (title == 'Dashboards' || title == 'Supplier Profile') {
      locationFinal = locationSplit[locationSplit.length - 1];
    } else {
      locationFinal = title;
    }
    this.currentUser = JSON.parse(sessionStorage.getItem("loginUser")!);
    var description;
    var title;
    this.descriptionPages = JSON.parse(sessionStorage.getItem("descriptionPages")!);
    if (this.currentUser.language == "it") {
      switch (locationFinal) {
        case 'dashboard_client':
          this.descriptionPages.forEach(element => {
            if (element['name_page'] == 'Dashboard cliente' && element['language'] == 1) {
              description = element['description'];
              title = element['title'];
            }
          });
          break;
        case 'dashboard_supplier':
          this.descriptionPages.forEach(element => {
            if (element['name_page'] == 'Dashboard Fornitore' && element['language'] == 1) {
              description = element['description'];
              title = element['title'];
            }
          });
          break;
        case 'Need':
          this.descriptionPages.forEach(element => {
            if (element['name_page'] == 'Trova nuovo profilo' && element['language'] == 1) {
              description = element['description'];
              title = element['title'];
            }
          });
          break;
        case 'Need Resources':
          this.descriptionPages.forEach(element => {
            if (element['name_page'] == 'Posizioni aperte' && element['language'] == 1) {
              description = element['description'];
              title = element['title'];
            }
          });
          break;
        case 'Need Matches':
          this.descriptionPages.forEach(element => {
            if (element['name_page'] == 'Matches' && element['language'] == 1) {
              description = element['description'];
              title = element['title'];
            }
          });
          break;
        case 'Collaborations Invite':
          this.descriptionPages.forEach(element => {
            if (element['name_page'] == 'Ingaggi cliente' && element['language'] == 1) {
              description = element['description'];
              title = element['title'];
            }
          });
          break;
        case 'supplier_profile':
          this.descriptionPages.forEach(element => {
            if (element['name_page'] == 'Fornitori' && element['language'] == 1) {
              description = element['description'];
              title = element['title'];
            }
          });
          break;
        case 'supplier':
          this.descriptionPages.forEach(element => {
            if (element['name_page'] == 'Requisiti fornitori' && element['language'] == 1) {
              description = element['description'];
              title = element['title'];
            }
          });
          break;
        case 'My Plans':
          this.descriptionPages.forEach(element => {
            if (element['name_page'] == 'Acquisti in app' && element['language'] == 1) {
              description = element['description'];
              title = element['title'];
            }
          });
          break;
        case 'My Profile':
          this.descriptionPages.forEach(element => {
            if (element['name_page'] == 'Il mio profilo' && element['language'] == 1) {
              description = element['description'];
              title = element['title'];
            }
          });
          break;
        case 'Change Password':
          this.descriptionPages.forEach(element => {
            if (element['name_page'] == 'Cambio password' && element['language'] == 1) {
              description = element['description'];
              title = element['title'];
            }
          });
          break;
        case 'Company Profile':
          this.descriptionPages.forEach(element => {
            if (element['name_page'] == 'Profilo aziendale' && element['language'] == 1) {
              description = element['description'];
              title = element['title'];
            }
          });
          break;
        case 'Employees':
          this.descriptionPages.forEach(element => {
            if (element['name_page'] == 'Dipendenti' && element['language'] == 1) {
              description = element['description'];
              title = element['title'];
            }
          });
          break;
        case 'Work Experience':
          this.descriptionPages.forEach(element => {
            if (element['name_page'] == 'Esperienze lavorative dipendenti' && element['language'] == 1) {
              description = element['description'];
              title = element['title'];
            }
          });
          break;
        case 'Collaborations Request':
          this.descriptionPages.forEach(element => {
            if (element['name_page'] == 'Ingaggi fornitori' && element['language'] == 1) {
              description = element['description'];
              title = element['title'];
            }
          });
          break;
        case 'Customer Profile':
          this.descriptionPages.forEach(element => {
            if (element['name_page'] == 'Clienti' && element['language'] == 1) {
              description = element['description'];
              title = element['title'];
            }
          });
          break;
        case 'Digitize CV':
          this.descriptionPages.forEach(element => {
            if (element['name_page'] == 'CV digitale' && element['language'] == 1) {
              description = element['description'];
              title = element['title'];
            }
          });
          break;
        case 'Profile Detail':
          this.descriptionPages.forEach(element => {
            if (element['name_page'] == 'Dettaglio profilo(Ingaggi cliente)' && element['language'] == 1) {
              description = element['description'];
              title = element['title'];
            }
          });
          break;
        case 'Need Detail':
          this.descriptionPages.forEach(element => {
            if (element['name_page'] == 'Dettaglio progetto(Ingaggi cliente)' && element['language'] == 1) {
              description = element['description'];
              title = element['title'];
            }
          });
          break;
        case 'List Collaborations':
          this.descriptionPages.forEach(element => {
            if (element['name_page'] == 'Lista Collaborazioni' && element['language'] == 1) {
              description = element['description'];
              title = element['title'];
            }
          });
          break;
        case 'Upload CV':
          this.descriptionPages.forEach(element => {
            if (element['name_page'] == 'Carica CV' && element['language'] == 1) {
              description = element['description'];
              title = element['title'];
            }
          });
          break;
        case 'Company Detail':
          this.descriptionPages.forEach(element => {
            if (element['name_page'] == 'Dettaglio compagnia(Ingaggi cliente)' && element['language'] == 1) {
              description = element['description'];
              title = element['title'];
            }
          });
          break;
        case 'Manage Users Client':
          this.descriptionPages.forEach(element => {
            if (element['name_page'] == 'Gestione utenti cliente' && element['language'] == 1) {
              description = element['description'];
              title = element['title'];
            }
          });
          break;
        case 'Manage Users Supplier':
          this.descriptionPages.forEach(element => {
            if (element['name_page'] == 'Gestione utenti fornitore' && element['language'] == 1) {
              description = element['description'];
              title = element['title'];
            }
          });
          break;
        case 'Position List':
          this.descriptionPages.forEach(element => {
            if (element['name_page'] == 'Posizioni aperte' && element['language'] == 1) {
              description = element['description'];
              title = element['title'];
            }
          });
          break;
        case 'Profile Available':
          this.descriptionPages.forEach(element => {
            if (element['name_page'] == 'Profili disponibili' && element['language'] == 1) {
              description = element['description'];
              title = element['title'];
            }
          });
          break;
        case 'Detail Position':
          this.descriptionPages.forEach(element => {
            if (element['name_page'] == 'Dettaglio posizione(posizioni aperte)' && element['language'] == 1) {
              description = element['description'];
              title = element['title'];
            }
          });
          break;
        case 'Detail Profile(Profile Available)':
          this.descriptionPages.forEach(element => {
            if (element['name_page'] == 'Dettaglio profilo(profili disponibili)' && element['language'] == 1) {
              description = element['description'];
              title = element['title'];
            }
          });
          break;
        case 'Project Creation':
          this.descriptionPages.forEach(element => {
            if (element['name_page'] == 'Creazione progetto' && element['language'] == 1) {
              description = element['description'];
              title = element['title'];
            }
          });
          break;
        case 'Candidacy Profile':
          this.descriptionPages.forEach(element => {
            if (element['name_page'] == 'Candida profili' && element['language'] == 1) {
              description = element['description'];
              title = element['title'];
            }
          });
          break;
        case 'Add Employee':
          this.descriptionPages.forEach(element => {
            if (element['name_page'] == 'Aggiungi profili' && element['language'] == 1) {
              description = element['description'];
              title = element['title'];
            }
          });
        case 'Saved Profiles':
          this.descriptionPages.forEach(element => {
            if (element['name_page'] == 'Profili salvati' && element['language'] == 1) {
              description = element['description'];
              title = element['title'];
            }
          });
          break;
        case 'Profile Accademy':
          this.descriptionPages.forEach(element => {
            if (element['name_page'] == 'Profili accademy' && element['language'] == 1) {
              description = element['description'];
              title = element['title'];
            }
          });
          break;
      }
    } else {
      switch (locationFinal) {
        case 'dashboard_client':
          this.descriptionPages.forEach(element => {
            if (element['name_page'] == 'Dashboard cliente' && element['language'] == 2) {
              description = element['description'];
              title = element['title'];
            }
          });
          break;
        case 'dashboard_supplier':
          this.descriptionPages.forEach(element => {
            if (element['name_page'] == 'Dashboard Fornitore' && element['language'] == 2) {
              description = element['description'];
              title = element['title'];
            }
          });
          break;
        case 'Need':
          this.descriptionPages.forEach(element => {
            if (element['name_page'] == 'Trova nuovo profilo' && element['language'] == 2) {
              description = element['description'];
              title = element['title'];
            }
          });
          break;
        case 'Need Resources':
          this.descriptionPages.forEach(element => {
            if (element['name_page'] == 'Posizioni aperte' && element['language'] == 2) {
              description = element['description'];
              title = element['title'];
            }
          });
          break;
        case 'Need Matches':
          this.descriptionPages.forEach(element => {
            if (element['name_page'] == 'Matches' && element['language'] == 2) {
              description = element['description'];
              title = element['title'];
            }
          });
          break;
        case 'Collaborations Invite':
          this.descriptionPages.forEach(element => {
            if (element['name_page'] == 'Ingaggi cliente' && element['language'] == 2) {
              description = element['description'];
              title = element['title'];
            }
          });
          break;
        case 'supplier_profile':
          this.descriptionPages.forEach(element => {
            if (element['name_page'] == 'Fornitori' && element['language'] == 2) {
              description = element['description'];
              title = element['title'];
            }
          });
          break;
        case 'supplier':
          this.descriptionPages.forEach(element => {
            if (element['name_page'] == 'Requisiti fornitori' && element['language'] == 2) {
              description = element['description'];
              title = element['title'];
            }
          });
          break;
        case 'My Plans':
          this.descriptionPages.forEach(element => {
            if (element['name_page'] == 'Acquisti in app' && element['language'] == 2) {
              description = element['description'];
              title = element['title'];
            }
          });
          break;
        case 'My Profile':
          this.descriptionPages.forEach(element => {
            if (element['name_page'] == 'Il mio profilo' && element['language'] == 2) {
              description = element['description'];
              title = element['title'];
            }
          });
          break;
        case 'Change Password':
          this.descriptionPages.forEach(element => {
            if (element['name_page'] == 'Cambio password' && element['language'] == 2) {
              description = element['description'];
              title = element['title'];
            }
          });
          break;
        case 'Company Profile':
          this.descriptionPages.forEach(element => {
            if (element['name_page'] == 'Profilo aziendale' && element['language'] == 2) {
              description = element['description'];
              title = element['title'];
            }
          });
          break;
        case 'Employees':
          this.descriptionPages.forEach(element => {
            if (element['name_page'] == 'Dipendenti' && element['language'] == 2) {
              description = element['description'];
              title = element['title'];
            }
          });
          break;
        case 'Work Experience':
          this.descriptionPages.forEach(element => {
            if (element['name_page'] == 'Esperienze lavorative dipendenti' && element['language'] == 2) {
              description = element['description'];
              title = element['title'];
            }
          });
          break;
        case 'Collaborations Request':
          this.descriptionPages.forEach(element => {
            if (element['name_page'] == 'Ingaggi fornitori' && element['language'] == 2) {
              description = element['description'];
              title = element['title'];
            }
          });
          break;
        case 'Customer Profile':
          this.descriptionPages.forEach(element => {
            if (element['name_page'] == 'Clienti' && element['language'] == 2) {
              description = element['description'];
              title = element['title'];
            }
          });
          break;
        case 'Digitize CV':
          this.descriptionPages.forEach(element => {
            if (element['name_page'] == 'CV digitale' && element['language'] == 2) {
              description = element['description'];
              title = element['title'];
            }
          });
          break;
        case 'Profile Detail':
          this.descriptionPages.forEach(element => {
            if (element['name_page'] == 'Dettaglio profilo(Ingaggi cliente)' && element['language'] == 2) {
              description = element['description'];
              title = element['title'];
            }
          });
          break;
        case 'Need Detail':
          this.descriptionPages.forEach(element => {
            if (element['name_page'] == 'Dettaglio progetto(Ingaggi cliente)' && element['language'] == 2) {
              description = element['description'];
              title = element['title'];
            }
          });
          break;
        case 'List Collaborations':
          this.descriptionPages.forEach(element => {
            if (element['name_page'] == 'Lista Collaborazioni' && element['language'] == 2) {
              description = element['description'];
              title = element['title'];
            }
          });
          break;
        case 'Upload CV':
          this.descriptionPages.forEach(element => {
            if (element['name_page'] == 'Carica CV' && element['language'] == 2) {
              description = element['description'];
              title = element['title'];
            }
          });
          break;
        case 'Company Detail':
          this.descriptionPages.forEach(element => {
            if (element['name_page'] == 'Dettaglio compagnia(Ingaggi cliente)' && element['language'] == 2) {
              description = element['description'];
              title = element['title'];
            }
          });
          break;
        case 'Manage Users Client':
          this.descriptionPages.forEach(element => {
            if (element['name_page'] == 'Gestione utenti cliente' && element['language'] == 2) {
              description = element['description'];
              title = element['title'];
            }
          });
          break;
        case 'Manage Users Supplier':
          this.descriptionPages.forEach(element => {
            if (element['name_page'] == 'Gestione utenti fornitore' && element['language'] == 2) {
              description = element['description'];
              title = element['title'];
            }
          });
          break;
        case 'Position List':
          this.descriptionPages.forEach(element => {
            if (element['name_page'] == 'Posizioni aperte' && element['language'] == 2) {
              description = element['description'];
              title = element['title'];
            }
          });
          break;
        case 'Profile Available':
          this.descriptionPages.forEach(element => {
            if (element['name_page'] == 'Profili disponibili' && element['language'] == 2) {
              description = element['description'];
              title = element['title'];
            }
          });
          break;
        case 'Detail Position':
          this.descriptionPages.forEach(element => {
            if (element['name_page'] == 'Dettaglio posizione(posizioni aperte)' && element['language'] == 2) {
              description = element['description'];
              title = element['title'];
            }
          });
          break;
        case 'Detail Profile(Profile Available)':
          this.descriptionPages.forEach(element => {
            if (element['name_page'] == 'Dettaglio profilo(profili disponibili)' && element['language'] == 2) {
              description = element['description'];
              title = element['title'];
            }
          });
          break;
        case 'Project Creation':
          this.descriptionPages.forEach(element => {
            if (element['name_page'] == 'Creazione progetto' && element['language'] == 2) {
              description = element['description'];
              title = element['title'];
            }
          });
          break;
        case 'Candidacy Profile':
          this.descriptionPages.forEach(element => {
            if (element['name_page'] == 'Candida profili' && element['language'] == 2) {
              description = element['description'];
              title = element['title'];
            }
          });
          break;
        case 'Add Employee':
          this.descriptionPages.forEach(element => {
            if (element['name_page'] == 'Aggiungi profili' && element['language'] == 2) {
              description = element['description'];
              title = element['title'];
            }
          });
        case 'Saved Profiles':
          this.descriptionPages.forEach(element => {
            if (element['name_page'] == 'Profili salvati' && element['language'] == 2) {
              description = element['description'];
              title = element['title'];
            }
          });
          break;
        case 'Profile Accademy':
          this.descriptionPages.forEach(element => {
            if (element['name_page'] == 'Profili accademy' && element['language'] == 1) {
              description = element['description'];
              title = element['title'];
            }
          });
          break;
      }
    }

    var obj = {
      'description': description,
      'title': title
    }

    const dialogRef = this.dialog.open(BreadCrumbDialogContent, {
      data: obj
    });

  }

  manageError() {
    if (this.currentUser.language == "it") {
      var errorMessage = this.translate.translations.it.ErrorServer;
    } else {
      var errorMessage = this.translate.translations.en.ErrorServer;
    }

    this._snackBar.open(errorMessage, '', {
      duration: 5000,
      panelClass: ['danger-snackbar'],
      horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,

    });
  }

}

@Component({
  templateUrl: 'dialog-content.html',
  styleUrls: ['./dialog-content.scss']
})
export class BreadCrumbDialogContent {

  description: any;


  constructor(public dialogRef: MatDialogRef<BreadCrumbDialogContent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: Page) {
    this.description = data;
    setTimeout(() => {
      let modal = document.getElementsByClassName('custom-modal')[0];
      let modalBase = document.getElementsByClassName('mat-dialog-container')[0];
      modal.setAttribute('style', 'opacity:1');
      modalBase.setAttribute('style', 'display:block');
    }, 400);
  }


  closeDialog() {
    let modalBase = document.getElementsByClassName('mat-dialog-container')[0];
    modalBase.setAttribute('style', 'display:none');
    this.dialogRef.close({ event: 'Cancel' });
  }


}

