<!-- ============================================================== -->
<!-- Main wrapper - style you can find in pages.scss -->
<!-- ============================================================== -->
<div class="main-container" [dir]="dir"
  [ngClass]="{'minisidebar': minisidebar, 'boxed': boxed, 'danger': danger, 'blue': blue, 'green': green, 'dark': dark,  'horizontal' : horizontal }">
  <div class="my-shade-custom" *ngIf="loadingSpin">
    <div class="spinner-container">
      <div class="loadGifCustom"></div>
      <img class="spin-image" [src]="logo" alt="spinner" width="100px">
    </div>
  </div>

  <!-- ============================================================== -->
  <!-- Topbar - style you can find in header.scss -->
  <!-- ============================================================== -->

  <!-- ============================================================== -->
  <!-- Logo - style you can find in header.scss -->
  <!-- ============================================================== -->

  <!-- ============================================================== -->
  <!-- sidebar toggle -->
  <!-- ============================================================== -->

  <!-- ============================================================== -->
  <!-- Search - style you can find in header.scss -->
  <!-- ============================================================== -->

  <!-- ============================================================== -->
  <!-- app header component - style you can find in header.scss / header.component.ts-->
  <!-- ============================================================== -->

  <!-- ============================================================== -->
  <!-- Right sidebar toggle - style you can find in rightsidebar.component.scss -->
  <!-- ============================================================== -->
  <div class="top-bar-custom"></div>
  <!-- ============================================================== -->
  <!-- End Topbar - style you can find in pages.scss -->
  <!-- ============================================================== -->
  <mat-sidenav-container class="example-sidenav-container" [style.marginTop.px]="mobileQuery.matches ? 0 : 0"
    [ngClass]="{'minisidebar': minisidebar}">
    <!-- ============================================================== -->
    <!-- Sidebar - style you can find in sidebar.scss -->
    <!-- ============================================================== -->


    <mat-sidenav #snav id="snav" class="pl-xs" [mode]="mobileQuery.matches ? 'side' : 'over'" fixedTopGap="0"
      [opened]="mobileQuery.matches" [disableClose]="mobileQuery.matches" (open)="sidebarOpened = true"
      (close)="sidebarOpened = false">

      <perfect-scrollbar *ngIf="!horizontal; else horizontalsidebar" class="scbar" [config]="config">

        <app-vertical-sidebar class="app-sidebar"
          (onSelectedMenuItem)="setToggle($event);snav.toggle() && clickEvent()">

        </app-vertical-sidebar>
      </perfect-scrollbar>

      <ng-template #horizontalsidebar>

        <app-horizontal-sidebar>

        </app-horizontal-sidebar>

      </ng-template>


    </mat-sidenav>

    <!-- ============================================================== -->
    <!-- Sidebar - style you can find in sidebar.scss -->
    <!-- ============================================================== -->
    <!-- ============================================================== -->
    <!-- Right Sidebar - style you can find in rightsidebar.component.scss -->
    <!-- ============================================================== -->
    <!-- ============================================================== -->
    <!-- Page container - style you can find in pages.scss -->
    <!-- ============================================================== -->
    <mat-sidenav-content class="page-wrapper" [ngClass]="status ? 'minitoggle' : ''">
      <div class="external-feedback-banner" *ngIf="seeBannerFeedback && versionProduct == 2 && (this.currentUser.role == 'Supplier' || this.currentUser.role ==
        'Freelancer') && checkUrlForBundle()">
        <div class="central-content">
          {{('FeedbackInternalExternal.UpVisibility' | translate)}} <span (click)="openDialog('feedbackRequest',{})">
            {{('FeedbackInternalExternal.AskReview' | translate)}} </span> <img src="" alt="">
        </div>
        <div class="close-banner" (click)="setClosedBanner()">
          <img src="assets/images/new-card-icons/remove-cancel-x.svg" alt="">
        </div>
      </div>
      <!-- <div class="alert-e">Alertone grossone</div> -->
      <mat-toolbar class=" custom-toolbar">
        <div class=" custom-navbar-container" [ngStyle]="{'margin-top': seeBannerFeedback == true ?' 6px' : '0px'}">
          <!-- ============================================================== -->
          <!-- Logo - style you can find in header.scss -->
          <!-- ============================================================== -->
          <button mat-icon-button (click)="snav.toggle() && clickEvent()" class="sidebar-toggle" value="sidebarclosed">
            <mat-icon class="menu-icon-custom">menu</mat-icon>
            <img class="open-navbar-custom" src="assets/images/icon/menu-sidebar.svg" alt="">
          </button>

          <!-- <div class="navbar-header "> -->

          <!-- <a class="navbar-brand" href="{{redirect}}"> -->
          <!-- Logo icon -->
          <!-- <b> -->
          <!--You can put here icon as well // <i class="wi wi-sunset"></i> //-->
          <!-- Dark Logo icon -->
          <!-- Light Logo icon -->
          <!-- <img src="assets/images/home-header.png" alt="homepage" class="light-logo company-logo-custom">
              </b> -->
          <!--End Logo icon -->
          <!-- Logo text -->
          <!-- </a>
          </div> -->
          <!-- ============================================================== -->
          <!-- sidebar toggle -->
          <!-- ============================================================== -->
          <!-- ============================================================== -->
          <!-- Search - style you can find in header.scss -->
          <!-- ============================================================== -->

          <!-- ============================================================== -->
          <!-- app header component - style you can find in header.scss / header.component.ts-->
          <!-- ============================================================== -->

          <app-vertical-header class="navbar-custom" [callbackFunction]="myCallbackFunction"
            [titleKey]="titlePageKey"></app-vertical-header>


          <!-- <ng-template #horizontalheader>
            <app-horizontal-header></app-horizontal-header>
          </ng-template> -->

          <!-- ============================================================== -->
          <!-- Right sidebar toggle - style you can find in rightsidebar.component.scss -->
          <!-- ============================================================== -->

        </div>

      </mat-toolbar>
      <app-breadcrumb>

      </app-breadcrumb>

      <div class="page-content">

        <button class="tf-v1-sidetab-button" (click)="openTypeform(2)" *ngIf='(currentUser.role == "Client" || currentUser.role == "Supplier"
        || currentUser.role == "Procurement Specialist"  || currentUser.role == "Supplier Management" || currentUser.role == "Accademy"
        || currentUser.role == "Management Accademy"  || currentUser.role == "Freelance") && versionProduct == 2 '><img
            src="assets/images/new-card-icons/icona-feedback.svg" alt=""></button>

        <router-outlet (activate)="onChildLoaded($event,$event)">
          <app-spinner></app-spinner>
        </router-outlet>

      </div>
    </mat-sidenav-content>
    <!-- ============================================================== -->
    <!-- Page container - style you can find in pages.scss -->
    <!-- ============================================================== -->
  </mat-sidenav-container>


</div>
