import { Injectable } from '@angular/core';
@Injectable({
	providedIn: 'root'
})
export class Helper {

	getUserData() {

		return JSON.parse(sessionStorage.getItem("loginUser")!);
	}

	getUserConfigData() {

		return JSON.parse(sessionStorage.getItem("loginUserToken")!);
	}

	// getDate(date) {

	//   var now = new Date(date);
	//   var y = now.getFullYear();
	//   var m = now.getMonth() + 1;
	//   var d = now.getDate();
	//   return y + '-' + (m < 10 ? '0' : '') + m + '-' + (d < 10 ? '0' : '') + d;
	// }

	// getCompanyImgUrl() {
	//   return this.getBaseUrl() + 'uploads/company/';
	// }

	getVersion() {
		return '1.77';
	}

	getLiveDeploy() {
		return 3;
	}

	// list of all local domains with docker project
	localDomainsInDockerProject = [
		"daytrend.local",
		"procurement.local",
		"esprinet.local",
		"engineering.local",
		"rapanas.local",
		"dm.local",
		"design.local",
		"esprinet2.local",
		"tsmc.local",
		"deloitte.local",
		"academy-lazy.local",
	];

	getBaseUrl() {
		if (this.localDomainsInDockerProject.includes(window.location.hostname)) {
			return 'http://api.daytrend.local/api/';
		} else if (window.location.host == 'localhost:4200') {
			return 'http://127.0.0.1:8000/api/'
			// return 'https://api.daytrend.it/api/';
			// return 'https://api.procurement.daytrend.it/api/'
		} else if (window.location.host.includes('4200')) {
			return 'http://127.0.0.1:8000/api/'
			// return 'https://api.daytrend.it/api/';
			// return 'https://api.procurement.daytrend.it/api/'
		} else {
			if (window.location.host == "marketplace.timeflow.it") {
				return 'https://mkt-api.timeflow.it/api/';
			} else if (window.location.host == "penetration-test.timeflow.it") {
				return 'https://api.penetration-test.timeflow.it/api/';
			} else if (window.location.host == "daytrend.it") {
				return 'https://api.daytrend.it/api/';
			} else if (window.location.host == "procurement.timeflow.it") {
				return 'https://api.procurement.timeflow.it/api/';
			} else if (window.location.host == "whitelabel.daytrend.it") {
				return 'https://api.whitelabel.daytrend.it/api/';
			} else if (window.location.host == "procurement.daytrend.it") {
				return 'https://api.procurement.daytrend.it/api/';
			} else if (window.location.host == "dev.mkt.daytrend.it") {
				return 'https://api-dev.mkt.daytrend.it/api/';
			} else if (window.location.host == "stage.marketplace.daytrend.it") {
				return 'https://api.stage.marketplace.daytrend.it/api/';
			} else if (window.location.host == "prod.procurement.daytrend.it") {
				return 'https://api.prod.procurement.daytrend.it/api/';
			} else if (window.location.host == "prod.marketplace.daytrend.it") {
				return 'https://api.prod.marketplace.daytrend.it/api/';
			} else if (window.location.host == "stage.procurement.daytrend.it") {
				return 'https://api.stage.procurement.daytrend.it/api/';
			} else if (window.location.host == "pre-prod.marketplace.daytrend.it") {
				return 'https://api.pre-prod.marketplace.daytrend.it/api/';
			} else if (window.location.host == "pre-prod.procurement.daytrend.it") {
				return 'https://api.pre-prod.procurement.daytrend.it/api/';
			} else {
				return 'https://api.stage.procurement.daytrend.it/api/';
			}
		}
	}
}
